import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { customPalette } from "shared/palettes";

export const IconStyle = styled.img`
  --size: 2rem;
  width: var(--size);
  height: var(--size);
  /* background-color: ${customPalette.primary}; */
`;

export const Container = styled(Box)`
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  :hover {
    background-color: ${customPalette.secondary};
    text-decoration: underline;
  }
  transition: 0.5s;

  height: 100%;
  width: ${(props) => (props.fullWidth ? "100%" : "5rem")};
  max-width: 7rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: 0.2rem; */
  margin: 0px 0rem;

  cursor: pointer; ;
`;
