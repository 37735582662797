import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const NavStyle = styled(Box)`
  height: 6vh;
`;

export const Content = styled(Box)`
  height: 94vh;
`;
