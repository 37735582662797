import { initialHelloState } from "shared/modules/hello";
import { EHelloAction } from "./hello.type";

export default (state = initialHelloState, action) => {
  switch (action.type) {
    // NOTE: login
    case EHelloAction.GET_HELLO_REQUEST:
      return {
        ...state,
        isPageLoading: true,
      };
    case EHelloAction.GET_HELLO_SUCCESS:
      return {
        ...state,
        isPageLoading: false,
      };
    case EHelloAction.GET_HELLO_FAILED:
      return {
        ...state,
        error: action.error,
        isPageLoading: false,
      };
    default: {
      return { ...state };
    }
  }
};
