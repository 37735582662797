import styled from "@emotion/styled";
import { Box, Button, Typography } from "@mui/material";

export const ButtonStyle = styled(Button)`
  /* flex: 6; */
  width: -webkit-fill-available;
  height: ${(props) => (props.heightauto ? "unset" : "2rem")};
  border-radius: 0.8rem;
  white-space: nowrap;
`;

export const LabelStyle = styled(Typography)`
  font-weight: bold;
  width: 4rem;
  font-size: smaller;
  align-self: center;

  flex: 2;
  white-space: nowrap;
  overflow: hidden;
`;

export const Container = styled(Box)`
  display: flex;
  margin: 0.2rem;
  width: ${(props) => (props.fullWidth ? "100%" : "7rem")};
  max-width: 7rem;
`;
