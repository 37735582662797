import styled from "@emotion/styled";
import { Box, Modal } from "@mui/material";
import { Button, Typography } from "components/ui";
import React from "react";
import useToggle from "shared/hooks/useToggle";
import { customPalette } from "shared/palettes";
import { ImgSmile } from "shared/utils/assets";
import { ContainerStyle } from "./styled";

const SectionStyle = styled(Box)`
  display: flex;
`;

const PanelStyle = styled(Box)`
  display: flex;
`;

const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  background: ${customPalette.default};
  padding: 1em;
  border-radius: 1rem;
`;

export default (props) => {
  const [pos, setPos] = React.useState();
  const modal = useToggle();

  const rand = () => {
    const min = 10;
    const max = 70;
    return Math.floor(Math.random() * (max - min) + min);
  };

  const onHover = () => {
    const x = rand() + "vw";
    const y = rand() + "vh";
    setPos({ x: x, y: y });
  };

  return (
    <ContainerStyle>
      <Modal open={modal.value} onClose={() => modal.toggle()}>
        <ModalContainer>
          <Typography center size="3em">
            {"Thank you, you are so kind."}
            <img src={ImgSmile} style={{ width: "3rem" }} />
          </Typography>
        </ModalContainer>
      </Modal>
      <Typography bolds size="3em" center>
        {`Did we go dinner together?`}
      </Typography>
      <SectionStyle>
        <PanelStyle>
          <Button onClick={() => modal.toggle()}>{`Yes`}</Button>
          {pos ? (
            <Button
              onMouseOver={onHover}
              onClick={onHover}
              style={{
                position: "absolute",
                left: pos.x,
                top: pos.y,
                width: "7rem",
              }}
            >{`No`}</Button>
          ) : (
            <Button onMouseOver={onHover} onClick={onHover}>{`No`}</Button>
          )}
        </PanelStyle>
      </SectionStyle>
    </ContainerStyle>
  );
};
