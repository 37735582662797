import React from "react";

export default (initial) => {
  const [value, setValue] = React.useState(initial || false);

  const on = () => {
    setValue(true);
  };

  const off = () => {
    setValue(false);
  };

  const toggle = () => {
    setValue(!value);
  };

  return { value, on, off, toggle };
};
