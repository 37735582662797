import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { customPalette } from "shared/palettes";

export const ContainerStyle = styled(Container)`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${customPalette.default};
  flex-direction: column;
`;
