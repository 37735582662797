import { Typography } from "components/ui";
import { motion } from "framer-motion";
import { ContainerStyle } from "./styled";

export default (props) => {
  const { duration } = props;

  const license = `Icons by svgrepo.com`;

  return (
    <ContainerStyle>
      <motion.div
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 10, opacity: 0 }}
        transition={{ duration: duration }}
      >
        <Typography bolds size="3em" center>
          {license}
        </Typography>
        <Typography bolds size="1em" center>
          {`
            I have community Albion Online,
            feel free to join and play together.
          `}
        </Typography>
      </motion.div>
    </ContainerStyle>
  );
};
