import styled from "@emotion/styled";
import { Box, Input, Typography } from "@mui/material";

export const InputStyle = styled(Input)`
  flex: 6;
`;

export const LabelStyle = styled(Typography)`
  font-weight: bold;
  width: 4rem;
  font-size: smaller;
  align-self: center;

  flex: 2;
  white-space: nowrap;
  overflow: hidden;
`;

export const Container = styled(Box)`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0.5rem; */

  display: flex;
`;
