import { EFace, ESuit } from "../enum";

export const createDeck = () => {
  var deck = [];
  Object.keys(ESuit).map((suit) =>
    Object.keys(EFace).map((face) => deck.push({ face: face, suit: suit }))
  );
  deck = shuffleDeck(deck);
  return deck;
};

export const shuffleDeck = (deck) => {
  var tdeck = deck;
  var m = tdeck.length,
    i;
  while (m) {
    i = Math.floor(Math.random() * m--);
    [tdeck[m], tdeck[i]] = [tdeck[i], tdeck[m]];
  }
  return tdeck;
};
