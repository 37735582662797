import styled from "@emotion/styled";
import { Container } from "@mui/system";
import { ButtonImg } from "components/ui";
import { customPalette } from "shared/palettes";

export const ContainerStyle = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${customPalette.primary};
  /* gap: 2em; */
  height: 100%;
`;

export const ButtonImgStyle = styled(ButtonImg)`
  padding: 0px 2em;
  width: 2em;
`;
