import { Container, ButtonStyle, LabelStyle } from "./styled";

const Button = (props) => {
  const { ...field } = props;

  return (
    <Container fullWidth={field?.fullWidth}>
      <ButtonStyle
        {...field}
        placeholder={field.placeholder}
        heightauto={field?.heightauto?.toString()}
      >
        {field.children}
      </ButtonStyle>
    </Container>
  );
};

export default Button;
