export const customPalette = {
  primary: "#008000",
  secondary: "#ccffcc",
  secondaryHover: "#ccffcccc",
  warning: "#F66B0E",
  error: "#ff531a",
  default: "#EFEFEF",
  black: "#000",
  hover: "#00cc00",
};

export const fontSize = {
  large: "x-large",
  medium: "medium",
  small: "small",
};
