import { Button, ButtonImg } from "components/ui";
import React from "react";
import { useNavigate } from "react-router-dom";
import { IconAbout, IconCard, IconHome } from "shared/utils/assets";
import { ENav, NavList } from "./enum";
import { ButtonImgStyle, ContainerStyle } from "./styled";
// import { useDispatch } from "react-redux";
// import { getHello } from "store/hello";

export default (props) => {
  const {} = props;
  //   const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tab, setTab] = React.useState(ENav.HOME);

  // const onGetHello = () => {
  //   getHello()(dispatch);
  // };

  const goPage = (ptab) => () => {
    switch (ptab) {
      case ENav.HOME:
        navigate(`/`);
        setTab(ENav.HOME);
        return;
      case ENav.CARD:
        navigate(`card`);
        setTab(ENav.CARD);
        return;
      case ENav.ABOUT:
        navigate(`about`);
        setTab(ENav.ABOUT);
        return;
      case ENav.YES:
        navigate(`yes`);
        setTab(ENav.YES);
        return;
      default:
        return;
    }
  };

  return (
    <ContainerStyle>
      {NavList.map((ptab) => (
        <ButtonImgStyle
          key={ptab["enum"]}
          src={ptab["src"]}
          onClick={goPage(ptab["enum"])}
          size={ptab["size"]}
          className={tab === ptab["enum"] ? "selected" : ""}
        />
      ))}
    </ContainerStyle>
  );
};
