import { createTheme, ThemeProvider } from "@mui/material";
import React from "react";
import { customPalette, fontSize } from "shared/palettes/constant";

const CustomTheme = (props) => {
  const { children } = props;

  const theme = createTheme({
    typography: {
      fontFamily: ["Prompt"].join(","),
    },
    palette: {
      primary: {
        main: customPalette.primary,
      },
      secondary: {
        main: customPalette.secondary,
      },
      default: {
        main: customPalette.default,
      },
    },
    components: {
      MuiButton: {
        variants: [
          {
            props: { color: "primary" },
            style: {
              backgroundColor: customPalette.primary,
              ":hover": {
                backgroundColor: customPalette.hover,
                boxShadow:
                  "0 2px 8px 0 rgba(0,0,0,0.2), 0 2px 8px 0 rgba(0,0,0,0.2)",
              },
            },
          },
          {
            props: { color: "secondary" },
            style: {
              backgroundColor: customPalette.secondary,
              color: customPalette.black,
            },
          },
          {
            props: { color: "warning" },
            style: {
              backgroundColor: customPalette.warning,
              color: customPalette.black,
            },
          },
          {
            props: { color: "error" },
            style: {
              backgroundColor: customPalette.error,
              color: customPalette.default,
            },
          },
          {
            props: { color: "default" },
            style: {
              backgroundColor: customPalette.default,
              color: customPalette.black,
            },
          },
        ],
        styleOverrides: {
          root: {
            color: customPalette.default,
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: { size: "large" },
            style: {
              fontSize: fontSize.large,
            },
          },
          {
            props: { size: "medium" },
            style: {
              fontSize: fontSize.medium,
            },
          },
          {
            props: { size: "small" },
            style: {
              fontSize: fontSize.small,
            },
          },
        ],
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default CustomTheme;
