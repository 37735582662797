import { IconAbout, IconCard, IconHome, IconYes } from "shared/utils/assets";

export const ENav = {
  HOME: "HOME",
  CARD: "CARD",
  ABOUT: "ABOUT",
  YES: "YES",
};

export const NavList = [
  { enum: ENav.HOME, src: IconHome, size: "2em" },
  { enum: ENav.CARD, src: IconCard, size: "2em" },
  { enum: ENav.ABOUT, src: IconAbout, size: "2em" },
  { enum: ENav.YES, src: IconYes, size: "3em" },
];
