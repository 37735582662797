import { Typography } from "components/ui";
import { motion } from "framer-motion";
import { ContainerStyle } from "./styled";

export default (props) => {
  const { duration } = props;

  const info = [
    `เบื่อแล้วคนในจอ อยากเป็นคนในใจ`,
    `ที่นี่ที่ไหน ใช่ที่รักป่าว`,
    `ไข่เจียวหมูสับ`,
    `ไค`,
    `หมูปิ้งชุดละ 20, 2 ชุด 40 3 ชุด 60 4 ชุด 80 5 ชุด 100 6 ชุด 120 7 ชุด 140`,
    `ช่วยผมด้วย ผมติดอยู่ในลิฟ`,
    `วันก่อนครับ วันนี้หลวม`,
    `เช้าฟาดฟัดฟัก เย็นค่ำฟักฟัด`,
    `กินพริก ระวังเผ็ด`,
    `...`,
    `ERROR 404. pls restart phon`,
  ];

  const rand = () => {
    return Math.floor(Math.random() * info.length);
  };

  return (
    <ContainerStyle>
      <motion.div
        // v1
        // initial={{ opacity: 0 }}
        // animate={{ opacity: 1 }}
        // exit={{ opacity: 0 }}
        // v2
        // initial={{ width: 0 }}
        // animate={{ width: "100%" }}
        // exit={{ x: window.innerWidth }}
        // v3
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 10, opacity: 0 }}
        transition={{ duration: duration }}
      >
        <Typography bolds size="5em" center>
          {info[rand()]}
        </Typography>
      </motion.div>
    </ContainerStyle>
  );
};
