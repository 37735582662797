import styled from "@emotion/styled";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { ButtonImg } from "components/ui";
import { customPalette } from "shared/palettes";

export const ContainerStyle = styled(Container)`
  background: ${customPalette.default};
  height: 100%;
  overflow-y: hidden;
`;

//
export const SectionStyle = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
//

export const DescribePanel = styled(Box)`
  display: flex;
  min-height: 3em;
  height: fit-content;
`;

export const DescribeTemplate = styled(Box)`
  display: flex;
  flex: 1;
`;

export const DescribeLabel = styled(Box)`
  display: flex;
  flex: 4;
  flex-direction: column;
`;

export const CardPanel = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100%;
`;

export const MainPanel = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
`;

export const CountDeckStyle = styled(Box)`
  border-radius: 2em;
  width: 4em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.2rem;
`;

export const ModalContainer = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: fit-content;
  transform: translate(-50%, -50%);
  background: ${customPalette.default};
  padding: 1em;
  border-radius: 1rem;
`;

export const ButtonImgStyle = styled(Box)`
  margin-top: -4em;
  margin-right: -3em;
  opacity: 0.2;
`;
