import { Modal } from "@mui/material";
import { ButtonImg, Typography } from "components/ui";
import { motion, transform, useAnimationControls } from "framer-motion";
import React from "react";
import useToggle from "shared/hooks/useToggle";
import { IconDeck, IconK, IconReset } from "shared/utils/assets";
import Card from "./components/Card";
import { EFace, ESuit } from "./enum";
import {
  ButtonImgStyle,
  CardPanel,
  ContainerStyle,
  CountDeckStyle,
  DescribeLabel,
  DescribePanel,
  DescribeTemplate,
  MainPanel,
  ModalContainer,
  SectionStyle,
} from "./styled";
import { createDeck } from "./utils/createDeck";

export default (props) => {
  const { duration } = props;
  const [face, setFace] = React.useState(EFace["5"]);
  const [suit, setSuit] = React.useState(ESuit.CLUB);
  const template = `ทำอะไร . . .`;
  const [describe, setDescribe] = React.useState(``);
  const [deck, setDeck] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const modal = useToggle();
  const [guide, setGuide] = React.useState(``);
  //
  const controls = useAnimationControls();

  React.useEffect(() => {
    onReset();
  }, []);

  React.useEffect(() => {
    if (count === 4) return modal.on();
  }, [count]);

  const onCloseModal = () => {
    modal.toggle();
    onReset();
  };

  const onReset = () => {
    onFadeReset();
    setDeck(createDeck());
    setFace("");
    setSuit("");
    setCount(0);
  };

  const onDraw = async () => {
    await onFadeIn();
    if (deck.length === 0) return alert(`หมด`);
    const draw = deck.pop();
    if (draw.face === "K") setCount(count + 1);
    setFace(draw.face);
    setSuit(draw.suit);
    setDeck(deck);
  };

  const onFadeReset = () => {
    controls.set({ x: -80, opacity: 0 });
    controls.start({ x: 0, opacity: 1 });
  };

  const onFadeIn = async () => {
    await controls.start({ x: 120, opacity: 0 });
    controls.set({ x: -80, opacity: 0, transition: { delay: 0.3 } });
    controls.start({ x: 0, opacity: 1, transition: { delay: 0.3 } });
  };

  return (
    <ContainerStyle>
      <motion.div
        initial={{ x: -10, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: 10, opacity: 0 }}
        transition={{ duration: duration }}
        style={{ height: "100%" }}
      >
        <Modal open={modal.value} onClose={onCloseModal}>
          <ModalContainer>
            <Typography center size="4em">
              {"K ใบสุดท้าย หมด 1 ช็อตหรือ 1 แก้ว"}
            </Typography>
          </ModalContainer>
        </Modal>
        <SectionStyle>
          <MainPanel style={{ flex: 1 }}>
            <ButtonImg src={IconReset} onClick={onReset} size={"3em"} />
            <CountDeckStyle>
              <Typography size="3em" zIndex="1" bolds>
                {deck.length}
              </Typography>
              <ButtonImgStyle>
                <ButtonImg size="4em" src={IconDeck} />
              </ButtonImgStyle>
            </CountDeckStyle>
            <CountDeckStyle>
              <Typography size="3em" zIndex="1" bolds>
                {count}
              </Typography>
              <ButtonImgStyle>
                <ButtonImg size="4em" src={IconK} />
              </ButtonImgStyle>
            </CountDeckStyle>
          </MainPanel>
          <motion.div whileTap={{ scale: 0.97 }} style={{ flex: 8 }}>
            <CardPanel onClick={onDraw}>
              <motion.div
                whileTap={{ scale: 0.97 }}
                animate={controls}
                transition={{ duration: 0.3 }}
                style={{ aspectRatio: "9/13" }}
              >
                <Card
                  face={face}
                  suit={suit}
                  setRule={setDescribe}
                  setRuleGuide={setGuide}
                />
              </motion.div>
            </CardPanel>
          </motion.div>
          <DescribePanel style={{ flex: 4 }}>
            {/* <DescribeTemplate>{template}</DescribeTemplate> */}
            <DescribeLabel>
              <Typography size="2em" bolds center>
                {describe}
              </Typography>
              <Typography size="1em" color="error" center>
                {guide}
              </Typography>
            </DescribeLabel>
          </DescribePanel>
        </SectionStyle>
      </motion.div>
    </ContainerStyle>
  );
};
