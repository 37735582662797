import { EFace, ERule, ERuleGuide, ESuit } from "pages/Card/enum";
import React from "react";
import {
  CoreSuit,
  IconClub,
  IconDiamond,
  IconHeart,
  IconSpade,
} from "shared/utils/assets";
import { BorderSuit } from "../../styled";

export default (propFace, propSuit) => {
  const hface = React.useMemo(() => {
    return propFace;
  }, [propFace]);

  const hsuit = React.useMemo(() => {
    switch (propSuit) {
      case ESuit.HEART:
        return IconHeart;
      case ESuit.DIAMOND:
        return IconDiamond;
      case ESuit.CLUB:
        return IconClub;
      case ESuit.SPADE:
        return IconSpade;
      default:
        return CoreSuit;
    }
  }, [propSuit]);

  const hrule = React.useMemo(() => {
    switch (propFace) {
      case EFace["A"]:
        return ERule["A"];
      case EFace["2"]:
        return ERule["2"];
      case EFace["3"]:
        return ERule["3"];
      case EFace["4"]:
        return ERule["4"];
      case EFace["5"]:
        return ERule["5"];
      case EFace["6"]:
        return ERule["6"];
      case EFace["7"]:
        return ERule["7"];
      case EFace["8"]:
        return ERule["8"];
      case EFace["9"]:
        return ERule["9"];
      case EFace["10"]:
        return ERule["10"];
      case EFace["J"]:
        return ERule["J"];
      case EFace["Q"]:
        return ERule["Q"];
      case EFace["K"]:
        return ERule["K"];
      default:
        return `คuใustoryu`;
    }
  }, [propFace]);

  const hruleGuide = React.useMemo(() => {
    switch (propFace) {
      case EFace["A"]:
        return ERuleGuide["A"];
      case EFace["2"]:
        return ERuleGuide["2"];
      case EFace["3"]:
        return ERuleGuide["3"];
      case EFace["4"]:
        return ERuleGuide["4"];
      case EFace["5"]:
        return ERuleGuide["5"];
      case EFace["6"]:
        return ERuleGuide["6"];
      case EFace["7"]:
        return ERuleGuide["7"];
      case EFace["8"]:
        return ERuleGuide["8"];
      case EFace["9"]:
        return ERuleGuide["9"];
      case EFace["10"]:
        return ERuleGuide["10"];
      case EFace["J"]:
        return ERuleGuide["J"];
      case EFace["Q"]:
        return ERuleGuide["Q"];
      case EFace["K"]:
        return ERuleGuide["K"];
      default:
        return ``;
    }
  }, [propFace]);

  return { hface, hsuit, hrule, hruleGuide };
};
