import styled from "@emotion/styled";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { customPalette } from "shared/palettes";

export const MenuItemStyle = styled(MenuItem)``;

export const SelectStyle = styled(Select)`
  /* flex: 6; */
  width: -webkit-fill-available;
  height: 2rem;
  border-radius: 0.8rem;
  white-space: nowrap;
  & .MuiSelect-select {
    text-indent: 1rem;
  }
`;

export const LabelStyle = styled(Typography)`
  font-weight: bold;
  width: 4rem;
  font-size: smaller;
  align-self: center;

  flex: 2;
  white-space: nowrap;
  overflow: hidden;
`;

export const Container = styled(Box)`
  /* display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0.5rem; */
  background-color: ${(props) =>
    props.color ? props.color : customPalette.default};
  border-radius: 0.8rem;

  display: flex;
  width: ${(props) => (props.fullWidth ? "100%" : "7rem")};
  max-width: 7rem;
  margin: 0.2rem;
`;
