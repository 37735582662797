import styled from "@emotion/styled";
import { Box, Chip } from "@mui/material";

export const ChipStyle = styled(Chip)`
  /* flex: 6; */
  width: -webkit-fill-available;
  height: 2rem;
  border-radius: 0.8rem;
  white-space: nowrap;
`;
export const Container = styled(Box)`
  display: flex;
  margin: 0.2rem;
  width: ${(props) => (props.fullWidth ? "100%" : "7rem")};
  max-width: 7rem;
`;
