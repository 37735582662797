import { AnimatePresence } from "framer-motion";
import About from "pages/About";
import Card from "pages/Card";
import Home from "pages/Home";
import Yes from "pages/Yes";
import { Route, Routes, useLocation } from "react-router-dom";

export default (props) => {
  const location = useLocation();
  const duration = 0.2;

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home duration={duration} />} />
        <Route path="card" element={<Card duration={duration} />} />
        <Route path="about" element={<About duration={duration} />} />
        <Route path="yes" element={<Yes duration={duration} />} />
      </Routes>
    </AnimatePresence>
  );
};
