import styled from "@emotion/styled";
import { Box } from "@mui/system";
import { Typography } from "components/ui";

export const CardBorder = styled(Box)`
  /* max-width: 80%; */
  /* max-height: 45vh; */
  aspect-ratio: 9 / 13;
  border: 5px solid #222;
  border-radius: 5px;
  display: flex;
  background: white;
  height: -webkit-fill-available;
`;

export const CardSuit = styled(Box)`
  height: 100%;
  display: flex;
  /* flex: 1; */
  flex-direction: ${(props) =>
    props.side === "top" ? "column" : "column-reverse"};
  margin: 0px 0.2em;
  z-index: 2;
`;

export const CardFaceBorder = styled(Box)`
  display: flex;
  flex: 6;
  justify-content: center;
  align-items: center;
`;

export const CardFace = styled(Box)`
  height: 80%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 7em;
`;

export const MiddleSuit = styled.img`
  aspect-ratio: 1 / 1;
  opacity: 0.2;
  position: absolute;
  width: 10em;
  z-index: 1;
`;

export const BorderSuit = styled.img`
  aspect-ratio: 1 / 1;
  width: 4em;
`;

export const LabelSuit = styled(Box)`
  margin: 0.2em;
`;
