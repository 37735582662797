import { applyMiddleware, combineReducers, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

import hello from "./hello/hello.reducer";

const rootReducer = combineReducers({
  hello: hello,
});

export default createStore(rootReducer, applyMiddleware(thunkMiddleware));
