import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ImgStyle = styled.img`
  --size: ${(props) => props.size || "2rem"};
  width: var(--size);
  height: var(--size);
  fill: red;
`;

export const Container = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: 0.2rem; */
  padding: 0px 0.5em;
  /* margin: 0px 0rem; */
  :hover {
    background: #00000022;
  }
  &.selected {
    --radius: 10%;
    background: #00000033;
    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }

  cursor: pointer;
`;
