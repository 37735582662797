import { Typography } from "components/ui";
import React from "react";
import { CoreSuit } from "shared/utils/assets";
import useCard from "./hooks/useCard";
import {
  BorderSuit,
  CardBorder,
  CardFace,
  CardFaceBorder,
  CardSuit,
  LabelSuit,
  MiddleSuit,
} from "./styled";

export default (props) => {
  const { face, suit, setRule, setRuleGuide } = props;
  const { hface, hsuit, hrule, hruleGuide } = useCard(face, suit);
  const fontSizeSuit = "4em";

  React.useEffect(() => {
    setRule(hrule);
  }, [hrule]);

  React.useEffect(() => {
    setRuleGuide(hruleGuide);
  }, [hruleGuide]);

  return (
    <CardBorder className={props.className}>
      <CardSuit side="top">
        <Typography bolds center size={fontSizeSuit}>
          {hface}
        </Typography>
        <LabelSuit bolds center>
          <BorderSuit src={hsuit} />
        </LabelSuit>
      </CardSuit>
      <CardFaceBorder>
        <CardFace>
          <MiddleSuit src={hsuit || CoreSuit} />
        </CardFace>
      </CardFaceBorder>
      <CardSuit side="botton">
        <Typography bolds center size={fontSizeSuit}>
          {hface}
        </Typography>
        <LabelSuit bolds center>
          <BorderSuit src={hsuit} />
        </LabelSuit>
      </CardSuit>
    </CardBorder>
  );
};
