import Typography from "../Typography";
import { Container, ImgStyle } from "./styled";

const ButtonImg = (props) => {
  const { ...field } = props;
  return (
    <Container className={field?.className} onClick={field?.onClick}>
      <ImgStyle src={field?.src} size={field?.size} />
    </Container>
  );
};

export default ButtonImg;
