export const ESuit = {
  HEART: "HEART",
  DIAMOND: "DIAMOND",
  CLUB: "CLUB",
  SPADE: "SPADE",
};

export const EFace = {
  A: "A",
  2: "2",
  3: "3",
  4: "4",
  5: "5",
  6: "6",
  7: "7",
  8: "8",
  9: "9",
  10: "10",
  J: "J",
  Q: "Q",
  K: "K",
};

export const ERule = {
  A: "เลือกใครก็ได้ ให้ 1 จิบ เบา ๆ",
  2: "เลือกใครก็ได้ แบ่งให้ครบ 2 จิบ",
  3: "เลือกใครก็ได้ แบ่งให้ครบ 3 จิบ",
  4: "เลือกใครก็ได้ แบ่งให้ครบ 4 จิบ",
  5: "เลือกใครก็ได้ แบ่งให้ครบ 5 จิบ",
  6: "เกมจังหวัด",
  7: "เกมแม่ 7",
  8: "รอด นั่งนิ่ง ๆ รอเก็บ",
  9: "คนขวามือ 1 จิบ",
  10: "คนซ้ายมือ 1 จิบ",
  J: "จับแก้วไว้ ใครจับแก้วคนสุดท้าย 1 จิบ",
  Q: "ยักคิ้วให้คนในสตอรี่หน่อย",
  // K: "1 ช็อตหรือ 1 แก้ว เบา ๆ",
  K: "สนับสนุนโดย คuใustoryu",
};

export const ERuleGuide = {
  A: "",
  2: "",
  3: "",
  4: "",
  5: "",
  6: "* สะกดชื่อจังหวัดวนรอบวง คนละ 1 คำ เช่น สะ-หมุด-ปรา-กาน ใครต่อไม่ได้ 1 จิบ *",
  7: "* นับเลขวนรอบวง โดยห้ามนับเลข 7 กับเลขที่หาร 7 ลง ใครนับผิด 1 จิบ *",
  8: "",
  9: "",
  10: "",
  J: "",
  Q: "",
  K: "",
};
