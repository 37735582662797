import CustomTheme from "components/Theme";
import Nav from "components/layout/Nav";
import { NavStyle, Content } from "./styled";
import TransitionRoute from "./components/TransitionRoute";

const AppRouter = () => {
  return (
    <CustomTheme>
      <NavStyle>
        <Nav />
      </NavStyle>
      <Content>
        <TransitionRoute />
      </Content>
    </CustomTheme>
  );
};

export default AppRouter;
