import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { customPalette, fontSize } from "shared/palettes";

export const TypographyStyle = styled(Typography)`
  font-size: ${(props) => props.size || fontSize.medium};
  /* color: ${(props) => props.colors || customPalette.black}; */
  text-transform: ${(props) =>
    Boolean(props.uppercase) ? "uppercase" : "unset"};
  font-weight: ${(props) => (Boolean(props.bolds) ? "bold" : "normal")};
  text-align: ${(props) => (Boolean(props.center) ? "center" : "unset")};
`;
