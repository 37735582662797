import { TypographyStyle } from "./styled";

const Typography = (props) => {
  const { ...field } = props;

  return (
    <TypographyStyle
      {...field}
      size={field?.size}
      colors={field?.colors}
      uppercase={field?.uppercase}
      bolds={field?.bolds?.toString()}
      center={field?.center?.toString()}
    >
      {field.children}
    </TypographyStyle>
  );
};

export default Typography;
